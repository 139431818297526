// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from "jquery";
window.jQuery = $;
window.$ = $;
require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("@fancyapps/fancybox")
require('bootstrap/dist/js/bootstrap.bundle.js')
require('packs/app')
require('packs/caption_images')
require('packs/scrolling_table')
require('owl.carousel')
// require('packs/banner_modal')
// require('packs/banner_strip')

import "controllers"

$( document ).ready(function(){
    if ($(document).width() > 700){
    $('.nav-item.dropdown-hover ').hover(function() {
       $(this).find('ul').addClass("show")
    },
    function() {
       $(this).find('ul').removeClass("show")
    });
    }
    else{
      $(".dropdown-toggle").attr("href", "#")
      $(".dropdown-toggle").attr("data-bs-toggle", "dropdown")
    }
    $('.navbar-toggler').click(function () {
      $(this).find('i').toggleClass("fa fa-bars fas fa-times")
    });
  })
